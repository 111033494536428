'use client';

import {
  UrqlProvider as Provider,
  ssrExchange,
  createClient,
  cacheExchange,
  fetchExchange,
} from '@urql/next';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { usePathname } from 'next/navigation';
import { PropsWithChildren, useEffect, useMemo } from 'react';

import { authUrlPatterns } from '@/constants/auth';
import { Paths } from '@/constants/path';
import { createPath } from '@/utils/path';

type Props = PropsWithChildren<{
  lng: string;
}>;

export function UrqlProvider({ lng, children }: Props): JSX.Element {
  const session = useSession();
  const router = useRouter();
  const pathname = usePathname();

  const token = useMemo(() => {
    return session.data?.accessToken ?? null;
  }, [session.data?.accessToken]);

  const isAuthenticatedUrl = useMemo(() => {
    return authUrlPatterns.some((pattern) => pattern.test(pathname ?? ''));
  }, [pathname]);

  const [client, ssr] = useMemo(() => {
    const ssr = ssrExchange({ isClient: true });
    const client = createClient({
      url: '/api/graphql',
      fetchOptions: {
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      },
      exchanges: [cacheExchange, ssr, fetchExchange],
      suspense: true,
    });
    return [client, ssr];
  }, [token]);

  // トークンのリフレッシュに失敗している状態で、認証が必要なページにアクセスした場合はログインページにリダイレクト
  useEffect(() => {
    if (
      isAuthenticatedUrl &&
      session?.data?.error === 'RefreshAccessTokenError'
    ) {
      router.push(createPath({ path: Paths.login }));
    }
  }, [isAuthenticatedUrl, lng, router, session]);

  return (
    <Provider client={client} ssr={ssr}>
      {children}
    </Provider>
  );
}
