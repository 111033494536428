'use client';
import { signInWithCustomToken } from 'firebase/auth';
import { useSession } from 'next-auth/react';
import { usePathname, useRouter } from 'next/navigation';
import { parseCookies } from 'nookies';
import { PropsWithChildren, useEffect, useState } from 'react';

import { languages } from '@/app/i18n/settings';
import { LoadingScreen } from '@/components/common/LoadingScreen';
import { authUrlPatterns } from '@/constants/auth';
import { Paths } from '@/constants/path';
import { auth } from '@/utils/firebase/client';
import { createPath } from '@/utils/path';

type Props = PropsWithChildren;

// TODO: middleware に移行できたら移行する
export const CheckAuth = ({ children }: Props) => {
  const session = useSession();
  const router = useRouter();
  const pathname = usePathname();
  const [isRedirect, setIsRedirect] = useState(false);

  useEffect(() => {
    const cookies = parseCookies();
    if (cookies.appCustomToken) {
      signInWithCustomToken(auth, cookies.appCustomToken)
        .then(({ user }) => {
          setIsRedirect(
            session.status === 'unauthenticated' &&
              !user &&
              authUrlPatterns.some((pattern) => pattern.test(pathname ?? ''))
          );
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setIsRedirect(
        session.status === 'unauthenticated' &&
          authUrlPatterns.some((pattern) => pattern.test(pathname ?? ''))
      );
    }
  }, [pathname, session]);

  useEffect(() => {
    if (
      isRedirect &&
      pathname &&
      !new RegExp(`^(/(${languages.join('|')}))?/login$`).test(pathname)
    ) {
      router.replace(
        `${createPath({
          path: Paths.login,
        })}?redirect=${pathname}`
      );
    }
  }, [isRedirect, pathname, router]);

  return (
    <>
      {session.status === 'authenticated' ||
      (pathname &&
        !authUrlPatterns.some((pattern) => pattern.test(pathname))) ? (
        children
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};
